import * as opApi from "@/api/offerPosition";
import * as opgApi from "@/api/offerPositionGroup";
import * as supplierRfqApi from "@/api/supplierRfq";
import { useRouteParams } from "@/composables/useRouteParams";
import type { OfferPosition } from "@/types/offerPosition";
import type { OfferPositionGroup } from "@/types/offerPositionGroup";
import type { Supplier } from "@/types/supplier";
import type { SupplierRFQ } from "@/types/supplierRfq";
import type { User } from "@/types/user";
import { isEmpty } from "lodash";
import { defineStore, storeToRefs } from "pinia";
import { debounce } from "quasar";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useCurrentInquiryStore } from "./currentInquiry";
import { useCurrentUserStore } from "./currentUser";

export const useCurrentOfferPositionGroupsStore = defineStore(
  "currentOfferPositionGroups",
  () => {
    const { organizationId, inboxId, inquiryId } = useRouteParams();
    const { currentRoute } = useRouter();

    const allOfferPositionGroups = ref<OfferPositionGroup[] | null>(null);
    const draftSupplierRfqsBySupplierId = ref<Map<number, SupplierRFQ>>(
      new Map()
    );

    const draftSuppliers = computed(() => {
      return Array.from(draftSupplierRfqsBySupplierId.value.values()).map(
        (supplierRfq: SupplierRFQ) => supplierRfq.supplier
      );
    });

    const filterText = ref("");
    const filterOffered = ref(false);
    const filterNotCompleted = ref(false);
    const filterSupplierRfqs = ref(false);
    const updateFilter = debounce(
      ({
        text,
        offered,
        notCompleted,
        supplierRfqs,
      }: Partial<{
        text: string;
        offered: boolean;
        notCompleted: boolean;
        supplierRfqs: boolean;
      }>) => {
        text !== undefined && (filterText.value = text);
        offered !== undefined && (filterOffered.value = offered);
        notCompleted !== undefined && (filterNotCompleted.value = notCompleted);
        supplierRfqs !== undefined && (filterSupplierRfqs.value = supplierRfqs);
      },
      300
    );

    const offerPositionGroups = computed(() => {
      if (!allOfferPositionGroups.value) return null;

      let offerPositionGroups = allOfferPositionGroups.value;

      if (filterOffered.value)
        offerPositionGroups = offerPositionGroups.filter(
          (opg) => opg.isOffered
        );

      if (filterNotCompleted.value)
        offerPositionGroups = offerPositionGroups.filter(
          (opg) => !opg.isCompleted
        );

      if (filterSupplierRfqs.value)
        offerPositionGroups = offerPositionGroups.filter(
          (opg) => opg.showSupplierRfqs
        );

      if (!filterText.value.length) return offerPositionGroups;

      const filterTextLc = filterText.value.toLowerCase();

      return offerPositionGroups.filter(
        (opg) =>
          opg.boqReferenceId?.toLowerCase().includes(filterTextLc) ||
          opg.boqText?.toLowerCase().includes(filterTextLc)
      );
    });

    async function update() {
      if (
        isNaN(organizationId.value) ||
        isNaN(inboxId.value) ||
        isNaN(inquiryId.value)
      ) {
        allOfferPositionGroups.value = null;
        return;
      }
      if (currentRoute.value.name !== "inquiry-positions") return;

      try {
        allOfferPositionGroups.value = await opgApi.getOfferPositionGroups(
          organizationId.value,
          inboxId.value,
          inquiryId.value
        );
      } catch (error: any) {
        if (error.response?.status === 404) {
          // 404 errors are handled by InquiryPositionsPage
          allOfferPositionGroups.value = null;
          return;
        } else {
          throw error;
        }
      }
      updateDraftSupplierRfqsBySupplierId();
    }
    watch(
      () => [organizationId.value, inboxId.value, inquiryId.value],
      async (newVal, oldVal) => {
        if ([0, 1, 2].every((i) => newVal[i] == oldVal[i])) return;
        allOfferPositionGroups.value = null;

        if (currentRoute.value.name !== "inquiry-positions") return;
        await update();
      }
    );
    watch(
      () => currentRoute.value.name,
      async () => {
        if (currentRoute.value.name !== "inquiry-positions") return;
        await update();
      }
    );
    if (currentRoute.value.name === "inquiry-positions") update();

    function updateDraftSupplierRfqsBySupplierId() {
      draftSupplierRfqsBySupplierId.value = new Map();
      allOfferPositionGroups.value?.forEach((opg) => {
        opg.supplierRfqsDraft.forEach((sr) => {
          draftSupplierRfqsBySupplierId.value.set(sr.supplier.id, sr);
        });
      });
    }

    async function addOfferPositionGroup(afterId: number | null) {
      if (!allOfferPositionGroups.value) return;
      const temporaryId = crypto.randomUUID();

      const opgBefore = afterId
        ? allOfferPositionGroups.value.find((opg) => opg.id === afterId)
        : null;

      const tempOfferPositionGroup: OfferPositionGroup = {
        id: getNewId(),
        order: opgBefore?.order || 0,
        documentId: null,
        requestedSupplierType: "NEUTRAL",
        isOffered: true,
        isCompleted: false,
        boundingBoxes: [],
        confidence: 1,
        boqReferenceId: null,
        boqText: null,
        boqTextShort: null,
        boqUnit: null,
        boqAmount: null,
        offerPositions: [],
        highlightPolygons: [],
        productCandidates: [],
        isManuallyCreated: true,
        showSupplierRfqs: false,
        supplierRfqsDraft: [],
        supplierRfqsNotDraft: [],
        temporaryId,
      };

      // insert the group after the group with the given ID
      if (afterId) {
        const index = allOfferPositionGroups.value.findIndex(
          (opg) => opg.id === afterId
        );
        allOfferPositionGroups.value.splice(
          index + 1,
          0,
          tempOfferPositionGroup
        );
      } else {
        allOfferPositionGroups.value.unshift(tempOfferPositionGroup);
      }

      try {
        const newOfferPositionGroup = await opgApi.addOfferPositionGroup(
          organizationId.value,
          inboxId.value,
          inquiryId.value,
          afterId || null,
          tempOfferPositionGroup
        );
        // update the local object since the ID assigned in the backend might be different
        Object.assign(tempOfferPositionGroup, newOfferPositionGroup);
        tempOfferPositionGroup.temporaryId = undefined;
      } catch (e) {
        // Remove the group if the addition fails
        allOfferPositionGroups.value = allOfferPositionGroups.value.filter(
          (opg) => opg.temporaryId !== temporaryId
        );
        return;
      }
    }

    async function deleteOfferPositionGroup(id: number) {
      if (!allOfferPositionGroups.value) return;

      allOfferPositionGroups.value = allOfferPositionGroups.value.filter(
        (opg) => opg.id !== id
      );

      await opgApi.deleteOfferPositionGroup(
        organizationId.value,
        inboxId.value,
        inquiryId.value,
        id
      );
    }

    async function moveOfferPositionGroupAfterAnother(
      firstId: number,
      secondId: number
    ) {
      if (!allOfferPositionGroups.value) return;

      const firstIndex = allOfferPositionGroups.value.findIndex(
        (opg) => opg.id === firstId
      );
      const secondIndex = allOfferPositionGroups.value.findIndex(
        (opg) => opg.id === secondId
      );

      if (firstIndex === -1 || secondIndex === -1) return;
      if (firstIndex === secondIndex) return;

      const first = allOfferPositionGroups.value[firstIndex];

      allOfferPositionGroups.value.splice(firstIndex, 1);
      allOfferPositionGroups.value.splice(secondIndex, 0, first);

      await opgApi.moveOfferPositionGroupAfterAnother(
        organizationId.value,
        inboxId.value,
        inquiryId.value,
        firstId,
        secondId
      );
    }

    async function updateOfferPositionGroup(
      id: number,
      data: Partial<OfferPositionGroup>
    ) {
      const offerPositionGroup = allOfferPositionGroups.value?.find(
        (opg) => opg.id === id
      );

      if (!offerPositionGroup) {
        throw new Error("Offer position group not found");
      }

      const oldData = { ...offerPositionGroup };

      Object.assign(offerPositionGroup, data);

      try {
        await opgApi.updateOfferPositionGroup(
          organizationId.value,
          inboxId.value,
          inquiryId.value,
          id,
          data
        );
      } catch (e) {
        Object.assign(offerPositionGroup, oldData);
      }
    }

    async function updateMultipleOfferPositionGroups(
      ids: number[],
      data: Partial<OfferPositionGroup>
    ) {
      if (!allOfferPositionGroups.value) return;

      const oldOfferPositionGroups = allOfferPositionGroups.value.filter(
        (opg) => ids.includes(opg.id)
      );

      const oldData = oldOfferPositionGroups?.map((opg) => ({ ...opg }));

      oldOfferPositionGroups.forEach((opg) => {
        Object.assign(opg, data);
      });

      try {
        await opgApi.updateMultipleOfferPositionGroups(
          organizationId.value,
          inboxId.value,
          inquiryId.value,
          ids,
          data
        );
      } catch (e) {
        if (oldOfferPositionGroups && oldData) {
          oldOfferPositionGroups.forEach((opg, i) =>
            Object.assign(opg, oldData[i])
          );
        }
      }
    }

    async function copyProducts(sourceGroupId: number, targetGroupId: number) {
      if (!allOfferPositionGroups.value)
        throw new Error("Offer position groups not found");

      const newTargetGroup = await opgApi.copyProducts(
        organizationId.value,
        inboxId.value,
        inquiryId.value,
        sourceGroupId,
        targetGroupId
      );

      // replace target group in allOfferPositionGroups
      allOfferPositionGroups.value = allOfferPositionGroups.value.map((opg) =>
        opg.id === targetGroupId ? newTargetGroup : opg
      );
    }

    async function addOfferPosition(
      offerPositionGroupId: number,
      data: Partial<OfferPosition>
    ) {
      const offerPositionGroup = allOfferPositionGroups.value?.find(
        (opg) => opg.id === offerPositionGroupId
      );

      if (!offerPositionGroup) {
        throw new Error("Offer position group not found");
      }

      if (!data.product) {
        throw new Error("Product is required");
      }

      if (!data.unit) {
        throw new Error("Unit is required");
      }

      if (!offerPositionGroup.isOffered) {
        // Automatically mark the group as offered if a position is added
        updateOfferPositionGroup(offerPositionGroupId, {
          isOffered: true,
        });
      }

      const temporaryId = crypto.randomUUID();

      const tempOfferPosition: OfferPosition = {
        id: getNewId(),
        order: offerPositionGroup?.offerPositions.length || 0,
        productConfidence: 1,
        variant: null,
        isAlternative: false,
        isAiSuggestion: false,
        notes: "",
        temporaryId,
        unitPrice: data.product?.listPrice ?? null,
        vatTaxRate: data.product?.vatTaxRate ?? null,
        ...(data as Pick<OfferPosition, "product" | "unit" | "amount"> &
          Partial<OfferPosition>),
      };

      offerPositionGroup.offerPositions.push(tempOfferPosition);

      try {
        const newOfferPosition = await opApi.addOfferPosition(
          organizationId.value,
          inboxId.value,
          inquiryId.value,
          offerPositionGroupId,
          tempOfferPosition
        );
        // update the local object since the ID assigned in the backend might be different
        Object.assign(tempOfferPosition, newOfferPosition);
      } catch (e) {
        // Remove the offer position if the addition fails
        offerPositionGroup.offerPositions =
          offerPositionGroup.offerPositions.filter(
            (op) => op.temporaryId !== temporaryId
          );
        return;
      }
    }

    async function updateOfferPosition(
      offerPositionGroupId: number,
      offerPositionId: number,
      data: Partial<OfferPosition>
    ) {
      if (isEmpty(data)) return;

      const offerPositionGroup = allOfferPositionGroups.value?.find(
        (opg) => opg.id === offerPositionGroupId
      );
      if (!offerPositionGroup) {
        throw new Error("Offer position group not found");
      }
      const index = offerPositionGroup.offerPositions.findIndex(
        (op) => op.id === offerPositionId
      );
      if (index === -1) return;
      const offerPosition = {
        ...offerPositionGroup.offerPositions[index],
        ...data,
      };
      offerPositionGroup.offerPositions.splice(index, 1, offerPosition);
      try {
        await opApi.updateOfferPosition(
          organizationId.value,
          inboxId.value,
          inquiryId.value,
          offerPositionGroupId,
          offerPositionId,
          data
        );
      } catch (e) {
        console.error(e);
        offerPositionGroup.offerPositions.splice(
          index,
          1,
          offerPositionGroup.offerPositions[index]
        );
      }
    }

    async function deleteOfferPosition(
      offerPositionGroupId: number,
      offerPositionId: number
    ) {
      const offerPositionGroup = allOfferPositionGroups.value?.find(
        (opg) => opg.id === offerPositionGroupId
      );

      if (!offerPositionGroup) {
        throw new Error("Offer position group not found");
      }

      const offerPositionIndex = offerPositionGroup.offerPositions.findIndex(
        (op) => op.id === offerPositionId
      );
      const offerPosition = offerPositionGroup.offerPositions.find(
        (op) => op.id === offerPositionId
      );

      if (!offerPosition) return;

      offerPositionGroup.offerPositions =
        offerPositionGroup.offerPositions.filter(
          (op) => op.id !== offerPositionId
        );

      try {
        await opApi.deleteOfferPosition(
          organizationId.value,
          inboxId.value,
          inquiryId.value,
          offerPositionGroupId,
          offerPositionId
        );
      } catch (e) {
        // Reinsert the offer position if the deletion fails
        offerPositionGroup.offerPositions.splice(
          offerPositionIndex,
          0,
          offerPosition
        );
        throw e;
      }
    }

    async function updateVariantConfiguration(
      offerPositionGroupId: number,
      offerPositionId: number,
      variant: string,
      addons: { product: string; variant: string }[]
    ) {
      const newOpg = await opgApi.updateVariantConfiguration(
        organizationId.value,
        inboxId.value,
        inquiryId.value,
        offerPositionGroupId,
        offerPositionId,
        variant,
        addons
      );

      const offerPositionGroup = allOfferPositionGroups.value?.find(
        (opg) => opg.id === offerPositionGroupId
      );

      if (!offerPositionGroup) {
        throw new Error("Offer position group not found");
      }

      Object.assign(offerPositionGroup, newOpg);
    }

    async function showSupplierRfqs(opgIds: number[]) {
      await updateMultipleOfferPositionGroups(opgIds, {
        showSupplierRfqs: true,
      });
    }

    async function hideSupplierRfqs(opgIds: number[]) {
      if (!offerPositionGroups.value)
        throw new Error("Offer position groups not found");

      await updateMultipleOfferPositionGroups(opgIds, {
        showSupplierRfqs: false,
      });
      const relevantOpgs = offerPositionGroups.value.filter((opg) =>
        opgIds.includes(opg.id)
      );
      const suppliers = relevantOpgs.flatMap((opg) =>
        opg.supplierRfqsDraft.map((rfq) => rfq.supplier)
      );
      suppliers.forEach((supplier) => {
        removeSupplierRfq(supplier, opgIds);
      });
    }

    async function addSupplierRfq(supplier: Supplier, opgIds: number[]) {
      if (draftSupplierRfqsBySupplierId.value.has(supplier.id)) {
        addToExistingSupplierRfq(
          draftSupplierRfqsBySupplierId.value.get(supplier.id) as SupplierRFQ,
          opgIds
        );
      } else {
        createNewSupplierRfq(supplier, opgIds);
      }
    }

    async function addToExistingSupplierRfq(
      supplierRfq: SupplierRFQ,
      opgIds: number[]
    ) {
      const relevantOpgs = offerPositionGroups.value?.filter((opg) =>
        opgIds.includes(opg.id)
      );
      if (!relevantOpgs) return;

      const previousSupplierRfqsDraft = relevantOpgs.map((opg) => [
        ...opg.supplierRfqsDraft,
      ]);

      relevantOpgs.forEach((opg) => {
        if (!opg.supplierRfqsDraft.find((rfq) => rfq.id === supplierRfq.id)) {
          opg.supplierRfqsDraft.push(supplierRfq);
        }
      });

      try {
        const apiSupplierRfq = await supplierRfqApi.addOpgsToSupplierRfq(
          inquiryId.value,
          supplierRfq.supplier.id,
          opgIds
        );

        Object.assign(supplierRfq, apiSupplierRfq);
      } catch (e) {
        relevantOpgs.forEach((opg, index) => {
          opg.supplierRfqsDraft = previousSupplierRfqsDraft[index];
        });
        throw e;
      }
    }

    const { inquiry } = storeToRefs(useCurrentInquiryStore());
    const { user } = storeToRefs(useCurrentUserStore());

    async function createNewSupplierRfq(supplier: Supplier, opgIds: number[]) {
      const relevantOpgs = offerPositionGroups.value?.filter((opg) =>
        opgIds.includes(opg.id)
      );
      if (!relevantOpgs) return;

      const temporaryId = crypto.randomUUID();

      const tempSupplierRfq: SupplierRFQ = {
        id: getNewSupplierRfqId(),
        temporaryId,
        assignedUser: user.value as User,
        dueDate: null,
        inquiry: {
          id: inquiry.value?.id || 0,
          shortCode: inquiry.value?.shortCode || "",
          inboxId: inquiry.value?.inbox || 0,
        },
        supplier: supplier,
        offerPositionGroups: relevantOpgs,
        recipientsTo: [],
        recipientsCc: [],
        recipientsBcc: [],
        boqDocumentFilenames: [],
        subject: "",
        body: "",
        attachOriginalBoq: false,
        status: "DRAFT",
        sentAt: null,
      };

      relevantOpgs.forEach((opg) => {
        if (
          opg.supplierRfqsDraft.find((rfq) => rfq.supplier.id === supplier.id)
        )
          return;
        opg.supplierRfqsDraft.push(tempSupplierRfq);
      });

      try {
        const apiSupplierRfq = await supplierRfqApi.addOpgsToSupplierRfq(
          inquiryId.value,
          supplier.id,
          opgIds
        );

        Object.assign(tempSupplierRfq, apiSupplierRfq);
        tempSupplierRfq.temporaryId = undefined;
      } catch (e) {
        relevantOpgs.forEach((opg) => {
          opg.supplierRfqsDraft = opg.supplierRfqsDraft.filter(
            (rfq) => rfq.temporaryId !== temporaryId
          );
        });
        throw e;
      }

      draftSupplierRfqsBySupplierId.value.set(supplier.id, tempSupplierRfq);
    }

    async function removeSupplierRfq(supplier: Supplier, opgIds: number[]) {
      if (!offerPositionGroups.value) return;

      const relevantOpgs = offerPositionGroups.value?.filter((opg) =>
        opgIds.includes(opg.id)
      );

      const existingSupplierRfqByOpg: Record<number, SupplierRFQ> = {};

      relevantOpgs.forEach((opg) => {
        const existingSupplierRfq = opg.supplierRfqsDraft.find(
          (rfq) => rfq.supplier.id === supplier.id
        );
        if (existingSupplierRfq) {
          existingSupplierRfqByOpg[opg.id] = existingSupplierRfq;
        }
        opg.supplierRfqsDraft = opg.supplierRfqsDraft.filter(
          (rfq) => rfq.supplier.id !== supplier.id
        );
      });

      try {
        await supplierRfqApi.removeOpgsFromSupplierRfq(
          inquiryId.value,
          supplier.id,
          opgIds
        );
      } catch (e) {
        relevantOpgs.forEach((opg) => {
          if (existingSupplierRfqByOpg[opg.id]) {
            opg.supplierRfqsDraft.push(existingSupplierRfqByOpg[opg.id]);
          }
        });
        throw e;
      }

      removeDraftSupplierRfqIfEmpty(supplier);
    }

    function removeDraftSupplierRfqIfEmpty(supplier: Supplier) {
      const supplierRfq = draftSupplierRfqsBySupplierId.value.get(supplier.id);
      if (!supplierRfq) return;

      if (
        offerPositionGroups.value?.every(
          (opg) => !opg.supplierRfqsDraft.includes(supplierRfq)
        )
      ) {
        draftSupplierRfqsBySupplierId.value.delete(supplier.id);
      }
    }

    function getNewId() {
      return (
        Math.max(
          0,
          ...(allOfferPositionGroups.value
            ?.flatMap((opg) => opg.offerPositions)
            .map((position) => position.id) || [])
        ) + 1
      );
    }

    function getNewSupplierRfqId() {
      return (
        Math.max(
          0,
          ...(allOfferPositionGroups.value
            ?.flatMap((opg) => [
              ...opg.supplierRfqsDraft,
              ...opg.supplierRfqsNotDraft,
            ])
            .map((rfq) => rfq.id) || [])
        ) + 1
      );
    }

    return {
      offerPositionGroups,
      updateOfferPositionGroup,
      updateMultipleOfferPositionGroups,
      updateFilter,
      addOfferPositionGroup,
      moveOfferPositionGroupAfterAnother,
      deleteOfferPositionGroup,
      addOfferPosition,
      updateOfferPosition,
      deleteOfferPosition,
      updateVariantConfiguration,
      showSupplierRfqs,
      hideSupplierRfqs,
      addSupplierRfq,
      removeSupplierRfq,
      copyProducts,
      draftSupplierRfqsBySupplierId,
      draftSuppliers,
    };
  }
);
