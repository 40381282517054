<template>
  <div class="slim-page">
    <settings-page-header section="organization" page="companyInformation" />
    <div class="column no-wrap gap-sm q-pa-md">
      <div class="text-h6">
        {{ $t("settings.organization.companyInformation.logo") }}
      </div>
      <div class="text-neutral-8 q-mb-md">
        {{ $t("settings.organization.companyInformation.logoDescription") }}
      </div>
      <logo-select v-if="details" :details="details" />
      <q-skeleton v-else height="100px" width="200px" />
    </div>
    <q-separator spaced />
    <q-form
      @submit="updateDetails"
      class="column no-wrap gap-sm q-pa-md q-mb-md"
    >
      <div class="text-h6 q-mb-md">
        {{ $t("settings.organization.companyInformation.details") }}
      </div>
      <input-or-skeleton autofocus property="companyName" />
      <input-or-skeleton property="street" />
      <div class="row no-wrap gap-sm">
        <input-or-skeleton property="postCode" />
        <input-or-skeleton property="town" class="col" />
      </div>
      <input-or-skeleton property="country" />
      <div class="row no-wrap gap-sm">
        <input-or-skeleton property="phoneNumber" class="col" />
        <input-or-skeleton property="faxNumber" class="col" />
      </div>
      <input-or-skeleton property="email" />
      <input-or-skeleton property="website" />
      <input-or-skeleton property="vatTaxId" />
      <input-or-skeleton property="taxId" />
      <input-or-skeleton property="registerId" />
      <input-or-skeleton property="managingDirectors" />
      <input-or-skeleton property="bankName" />
      <input-or-skeleton property="bankBic" />
      <input-or-skeleton property="bankIban" />
      <input-or-skeleton property="currency" />
      <div class="row justify-end">
        <q-btn
          dense
          class="float-right q-mt-md"
          type="submit"
          color="primary"
          :label="$t('settings.organization.companyInformation.save')"
          :disable="!details"
          :loading="isUpdating"
        />
      </div>
    </q-form>
  </div>
</template>

<script setup lang="ts">
import InputOrSkeleton from "@/components/Settings/Organization/CompanyInformation/InputOrSkeleton.vue";
import LogoSelect from "@/components/Settings/Organization/CompanyInformation/LogoSelect.vue";
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import type { OrganizationDetails } from "@/types/organization";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { provide, ref, watch, type Ref } from "vue";
import { useI18n } from "vue-i18n";

const q = useQuasar();
const { t } = useI18n();

const isUpdating = ref(false);

const store = useCurrentOrganizationStore();
const { organization } = storeToRefs(store);
const details = ref<OrganizationDetails | undefined>(undefined);
watch(
  organization,
  () => {
    // We need to clone the organization to avoid reactivity issues
    details.value = organization.value
      ? { ...organization.value.details }
      : undefined;
  },
  { immediate: true }
);

provide<Ref<OrganizationDetails | undefined>>("organizationDetails", details);

async function updateDetails() {
  if (!details.value) throw new Error("Details are undefined");

  isUpdating.value = true;

  try {
    await store.updateOrganization({
      details: details.value,
    });
    q.notify({
      message: t("settings.organization.companyInformation.saved"),
      color: "positive",
      actions: [{ label: t("OK"), color: "white" }],
    });
  } finally {
    isUpdating.value = false;
  }
}
</script>
