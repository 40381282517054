<template>
  <div class="fit column no-wrap">
    <q-item
      dense
      class="col-auto full-width positions-table-filter-bar row items-center q-px-md q-py-xs"
    >
      <div class="row no-wrap gap-x-md">
        <input
          dense
          outlined
          v-model="filterText"
          :placeholder="$t('inquiryPositionsPage.positionsFilter.text')"
          class="standalone-input"
          @keydown.stop.a
          @keydown.stop.f
        />
        <q-checkbox
          dense
          size="sm"
          v-model="filterOffered"
          :label="$t('inquiryPositionsPage.positionsFilter.offered')"
        />
        <q-checkbox
          dense
          size="sm"
          v-model="filterNotCompleted"
          :label="$t('inquiryPositionsPage.positionsFilter.notCompleted')"
        />
        <q-checkbox
          v-if="organization?.useSupplierData"
          dense
          size="sm"
          v-model="filterSupplierRfqs"
          :label="$t('inquiryPositionsPage.positionsFilter.supplierRfqs')"
        />
      </div>
      <q-space />
      {{ countIndicator }}
      <q-btn
        :disable="!canEditInquiryPositions"
        dense
        flat
        icon="sym_r_add"
        size="sm"
        @click="addGroup"
        class="q-ml-xs"
      >
        <q-tooltip>{{
          $t("inquiryPositionsPage.addOfferPositionGroup")
        }}</q-tooltip>
      </q-btn>
    </q-item>
    <q-virtual-scroll
      v-if="offerPositionGroups"
      ref="virtualScroll"
      separator
      class="positions-table full-width"
      :items="offerPositionGroups"
      v-slot="{ item: group }"
      :virtual-scroll-slice-ratio-before="2"
      :virtual-scroll-slice-ratio-after="2"
      :virtual-scroll-item-size="30"
    >
      <offer-position-group
        :group="group"
        :key="group.id"
        :ref="el => (groupElRefs[group.id] = el as typeof OfferPositionGroup | null)"
        :is-selected="selectedGroupIds.includes(group.id)"
        :disabled="!canEditInquiryPositions"
        :draggable="group.isManuallyCreated"
        @headerclick="(event) => handleOpgHeaderClick(event, group.id)"
        @activate="selectGroupId(group.id)"
        @drop="(e: DragEvent) => moveOfferPositionGroupAfterAnother(group.id, e)"
        @dragover="onDragOver"
        @dragstart="(e: DragEvent) => onDragStart(group.id, e)"
        @set-offered="(isOffered) => handleOpgSetOffered(group.id, isOffered)"
        @set-completed="
          (isCompleted) => handleOpgSetCompleted(group.id, isCompleted)
        "
        @inputclick="selectIfNotSelected(group.id)"
      />
    </q-virtual-scroll>
    <positions-table-context-menu
      :selected-group-ids="selectedGroupIds"
      :disabled="!canEditInquiryPositions"
      :clickedGroupId="contextMenuTargetGroupId"
      :copy-products-source-group-id="copyProductsSourceGroupId"
      @update-show-supplier-rfqs="
        (showSupplierRfqs) => showSupplierRfqs && collapseSelected()
      "
      @update:copy-products-source-group-id="
        (groupId: number | null) => (copyProductsSourceGroupId = groupId)
      "
    />
  </div>
</template>

<script setup lang="ts">
import { usePositionsTableKeyboardShortcuts } from "@/composables/positionsTable/usePositionsTableKeyboardShortcuts";
import { useSelection } from "@/composables/positionsTable/useSelection";
import { provideSupplierRfq } from "@/composables/positionsTable/useSupplierRfq";
import { usePositionsEvents } from "@/composables/usePositionsEvents";
import { useRouteParams } from "@/composables/useRouteParams";
import { useSelectedPositionIds } from "@/composables/useSelectedPositionIds";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import type { OfferPositionGroup as OfferPositionGroupType } from "@/types/offerPositionGroup";
import { cmdOrCtrlPressed } from "@/utils/cmdOrCtrl";
import { storeToRefs } from "pinia";
import type { QVirtualScroll } from "quasar";
import type { DragEvent } from "react";
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
  watchEffect,
} from "vue";
import { useI18n } from "vue-i18n";
import OfferPositionGroup from "./OfferPositionGroup/OfferPositionGroup.vue";
import PositionsTableContextMenu from "./PositionsTableContextMenu.vue";

const { organization } = storeToRefs(useCurrentOrganizationStore());

const store = useCurrentOfferPositionGroupsStore();
const { offerPositionGroups } = storeToRefs(store);

const { canEditInquiryPositions } = storeToRefs(useCurrentInquiryStore());

const virtualScroll = ref<QVirtualScroll | null>(null);

const groupElRefs = ref<Record<number, typeof OfferPositionGroup | null>>({});

const contextMenuTargetGroupId = ref<number | null>(null);
const copyProductsSourceGroupId = ref<number | null>(null);

const { t } = useI18n();

const { updateFilter } = store;
const filterText = ref("");
const filterOffered = ref(false);
const filterNotCompleted = ref(false);
const filterSupplierRfqs = ref(false);
watchEffect(() =>
  updateFilter({
    text: filterText.value,
    offered: filterOffered.value,
    notCompleted: filterNotCompleted.value,
    supplierRfqs: filterSupplierRfqs.value,
  })
);

const events = usePositionsEvents();

function handlePdfRowClick(groupId: number) {
  const index = getIndex(groupId);
  if (index === null) return;
  select(index);
  scrollToIndex(index);
}

function handlePdfOverlayClick() {
  unselect();
}

onMounted(() => {
  events.on("pdfRowClick", handlePdfRowClick);
  events.on("pdfOverlayClick", handlePdfOverlayClick);
});
onBeforeUnmount(() => {
  events.off("pdfRowClick", handlePdfRowClick);
  events.off("pdfOverlayClick", handlePdfOverlayClick);
});

const numRows = computed(() => offerPositionGroups.value?.length || 0);
const countIndicator = computed(() => {
  if (selectedIndices.value.length === 0)
    return t("inquiryPositionsPage.positionsTable.nPositions", {
      positions: numRows.value,
    });
  return t("inquiryPositionsPage.positionsTable.nSelected", {
    selected: selectedIndices.value.length,
    positions: numRows.value,
  });
});

const {
  selectedIndices,
  select,
  selectNext,
  selectPrevious,
  extendSelectNext,
  extendSelectPrevious,
  extendSelectIndex,
  toggleSelectIndex,
  unselect,
  selectAll,
} = useSelection(numRows);
const selectedGroupIds = computed(
  () =>
    selectedIndices.value
      .map((index) => getId(index))
      .filter((id) => id) as number[]
);
const selectedPositionIds = useSelectedPositionIds();
watch(selectedGroupIds, (newIds) => {
  selectedPositionIds.value = newIds;
});

provideSupplierRfq(selectedGroupIds);

function selectGroupId(groupId: number | null) {
  select(getIndex(groupId));
}

function handleOpgHeaderClick(event: MouseEvent, groupId: number) {
  event.stopPropagation();
  event.preventDefault();

  if (event.button === 2) {
    selectIfNotSelected(groupId, false);
    contextMenuTargetGroupId.value = groupId;
    return;
  }

  const index = getIndex(groupId);

  if (index === null) throw new Error("Group not found");

  if (cmdOrCtrlPressed(event)) {
    toggleSelectIndex(index);
  } else if (event.shiftKey) {
    window.getSelection()?.removeAllRanges(); // prevent accidental text selection
    extendSelectIndex(index);
  } else {
    select(index);
    events.emit("tableRowClick", groupId);
  }
}

function selectIfNotSelected(groupId: number, scrollPdf: boolean = true) {
  const index = getIndex(groupId);
  if (index === null) throw new Error("Group not found");
  if (selectedGroupIds.value.includes(groupId)) return;
  select(index);
  if (scrollPdf) events.emit("tableRowClick", groupId);
}

function activateSelected() {
  const id = getId(selectedIndices.value[0]);
  if (!id) return;

  const group = offerPositionGroups.value?.find((g) => g.id === id);
  if (!group) return;

  const el = groupElRefs.value[id];

  if (el) {
    el.activate();
  }
}

async function handleOpgSetOffered(groupId: number, isOffered: boolean) {
  if (selectedGroupIds.value.includes(groupId)) {
    setOfferedSelected(isOffered);
  } else {
    // set only this group as offered
    selectIfNotSelected(groupId);
    const data: Partial<OfferPositionGroupType> = { isOffered };
    if (!isOffered) data.isCompleted = false;

    await store.updateOfferPositionGroup(groupId, data);
  }
}

async function handleOpgSetCompleted(groupId: number, isCompleted: boolean) {
  if (selectedGroupIds.value.includes(groupId)) {
    setCompletedSelected(isCompleted);
  } else {
    // set only this group as completed
    selectIfNotSelected(groupId);
    const data: Partial<OfferPositionGroupType> = { isCompleted };
    if (isCompleted) data.isOffered = true;

    await store.updateOfferPositionGroup(groupId, data);
  }
}

function toggleOfferedSelected() {
  const id = getId(selectedIndices.value[0]);
  if (!id) return;
  const group = offerPositionGroups.value?.find((g) => g.id === id);
  if (!group) return;
  setOfferedSelected(!group.isOffered);
}

function toggleCompletedSelected() {
  const id = getId(selectedIndices.value[0]);
  if (!id) return;
  const group = offerPositionGroups.value?.find((g) => g.id === id);
  if (!group) return;
  setCompletedSelected(!group.isCompleted);
}

async function setOfferedSelected(isOffered: boolean) {
  if (!canEditInquiryPositions.value) return;
  const data: Partial<OfferPositionGroupType> = { isOffered };
  if (!isOffered) data.isCompleted = false;
  await store.updateMultipleOfferPositionGroups(selectedGroupIds.value, data);
}

function setCompletedSelected(isCompleted: boolean) {
  if (!canEditInquiryPositions.value) return;
  const data: Partial<OfferPositionGroupType> = { isCompleted };
  if (isCompleted) data.isOffered = true;
  store.updateMultipleOfferPositionGroups(selectedGroupIds.value, data);
}

function expandSelected() {
  selectedIndices.value
    .map(getId)
    .forEach((id: number | null) => id && groupElRefs.value[id]?.expand());
}

function collapseSelected() {
  selectedIndices.value
    .map(getId)
    .forEach((id: number | null) => id && groupElRefs.value[id]?.collapse());
}

function handleDownArrow() {
  selectNext();
  scrollToIndex(selectedIndices.value[0]);
  const groupId = getId(selectedIndices.value[0]);
  if (groupId) events.emit("tableRowClick", groupId);
}

function handleUpArrow() {
  selectPrevious();
  scrollToIndex(selectedIndices.value[0]);
  const groupId = getId(selectedIndices.value[0]);
  if (groupId) events.emit("tableRowClick", groupId);
}

function handleShiftDownArrow() {
  extendSelectNext();
  const lastIndex = selectedIndices.value.at(-1);
  if (lastIndex !== undefined) scrollToIndex(lastIndex);
}

function handleShiftUpArrow() {
  extendSelectPrevious();
  const firstIndex = selectedIndices.value[0];
  if (firstIndex !== undefined) scrollToIndex(firstIndex);
}

usePositionsTableKeyboardShortcuts({
  onEnter: activateSelected,
  onA: toggleOfferedSelected,
  onF: toggleCompletedSelected,
  onRightArrow: expandSelected,
  onLeftArrow: collapseSelected,
  onDownArrow: handleDownArrow,
  onUpArrow: handleUpArrow,
  onShiftDownArrow: handleShiftDownArrow,
  onShiftUpArrow: handleShiftUpArrow,
  onEscape: unselect,
  onCtrlOrCmdA: selectAll,
});

const { inquiryId } = useRouteParams();
watch(inquiryId, unselect);

function scrollToIndex(index: number | null) {
  if (index === null) return;
  virtualScroll.value?.scrollTo(index, "center");
}

function getIndex(groupId: number | null): number | null {
  if (groupId === null) return null;
  const index = offerPositionGroups.value?.findIndex((g) => g.id === groupId);
  return index === -1 || index === undefined ? null : index;
}
function getId(index: number | null | undefined): number | null {
  if (index === null || index == undefined) return null;
  return offerPositionGroups.value?.[index]?.id || null;
}

function addGroup() {
  const lastSelectedIndex = selectedIndices.value.at(-1);
  const lastSelectedId = getId(lastSelectedIndex);
  store.addOfferPositionGroup(lastSelectedId);
}

function onDragStart(groupId: number, event: DragEvent) {
  event.dataTransfer.setData("groupId", groupId.toString());
}

function moveOfferPositionGroupAfterAnother(groupId: number, event: DragEvent) {
  const targetGroupId = parseInt(event.dataTransfer?.getData("groupId") || "");
  if (isNaN(targetGroupId)) return;

  store.moveOfferPositionGroupAfterAnother(targetGroupId, groupId);
}

function onDragOver(event: DragEvent) {
  event.preventDefault();
  event.dataTransfer.dropEffect = "move";
}
</script>

<style scoped lang="scss">
.positions-table {
  height: 100%;
  flex-shrink: 1;
  flex-grow: 0;
}

.positions-table-filter-bar {
  border-bottom: 1px solid $separator-color;
  font-size: smaller;
}
</style>
