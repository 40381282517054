<template>
  <div
    class="dropzone"
    @drop="onDrop"
    @dragover="onDragOver"
    @dragleave="onDragLeave"
  >
    <slot />
    <div :class="`drag-overlay ${isDragOver ? 'drag-over' : ''}`">
      <slot name="overlay">
        <div class="text-xl">{{ $t("Drop files here") }}</div>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

const emit = defineEmits(["drop"]);

const isDragOver = ref(false);

async function onDrop(event: DragEvent) {
  event.preventDefault();
  const items = event.dataTransfer?.items || [];
  const files = await parseDirectories(items);
  console.log(event);
  console.log(files);
  if (files.length > 0) emit("drop", files);
  isDragOver.value = false;
}

async function parseDirectories(
  items: DataTransferItemList | never[]
): Promise<File[]> {
  const files: File[] = [];
  for (let i = 0; i < items.length; i++) {
    if (items[i].webkitGetAsEntry()?.isDirectory) {
      const directoryFiles = await readDirectory(
        items[i].webkitGetAsEntry() as FileSystemDirectoryEntry
      );
      files.push(...directoryFiles);
    } else {
      const file = items[i].getAsFile();
      if (file) files.push(file);
    }
  }

  return files;
}

// Read directory recursively
async function readDirectory(
  directory: FileSystemDirectoryEntry
): Promise<File[]> {
  const files: File[] = [];
  const reader = directory.createReader();

  async function readEntries(): Promise<File[]> {
    const entries = await readEntriesAsync(reader);

    for (const entry of entries) {
      if (entry.isFile) {
        const file = await getFileAsync(entry as FileSystemFileEntry);
        files.push(file);
      } else {
        const subFiles = await readDirectory(entry as FileSystemDirectoryEntry);
        files.push(...subFiles);
      }
    }

    if (entries.length > 0) {
      return readEntries();
    } else {
      return files;
    }
  }

  return await readEntries();
}

// Read entries from directory reader
function readEntriesAsync(
  reader: FileSystemDirectoryReader
): Promise<FileSystemEntry[]> {
  return new Promise((resolve) => {
    reader.readEntries(resolve);
  });
}

// Get file from file entry
function getFileAsync(fileEntry: FileSystemFileEntry): Promise<File> {
  return new Promise((resolve) => {
    fileEntry.file(resolve);
  });
}

function onDragOver(event: DragEvent) {
  event.preventDefault();
  isDragOver.value = true;
}

function onDragLeave(event: DragEvent) {
  event.preventDefault();
  isDragOver.value = false;
}
</script>

<style lang="scss">
.dropzone {
  position: relative;
}

.drag-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px dashed $neutral-6;
  border-width: 2px;
  border-spacing: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  color: $neutral-8;

  &.drag-over {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
