import { cmdOrCtrlSymbol } from "@/utils/cmdOrCtrl";

export default {
  loggedInLayout: {
    organizationNotFound: "Diese Organisation scheint nicht zu existieren :-(",
    backToRoot: "Zurück zur Startseite",
  },
  deleteSingleInquiryMenuEntry: {
    label: "Anfrage löschen",
    confirmDelete: {
      title: "Anfrage löschen",
      message:
        "Sind Sie sicher, dass Sie Anfrage {inquiryId} unwiderbringlich löschen möchten?",
    },
  },
  deleteMultiInquiryButton: {
    confirmDelete: {
      title: "Anfragen löschen",
      message:
        "Sind Sie sicher, dass Sie die ausgewählten {numInquiries} Anfragen löschen möchten?",
    },
  },
  moveMultiInquiryButton: {
    label: "In anderen Ordner verschieben",
    confirmMove: {
      title: "Anfragen verschieben",
      message:
        "Sind Sie sicher, dass Sie die ausgewählten {numInquiries} Anfragen in den Ordner {targetInbox} verschieben möchten?",
      ok: "Verschieben",
    },
  },
  moveInquiryButton: {
    label: "Anfrage verschieben",
    confirmMove: {
      title: "Anfrage verschieben",
      message:
        "Sind Sie sicher, dass Sie die Anfrage {inquiryId} in den Ordner '{targetInboxName}' verschieben möchten?",
      ok: "Anfrage verschieben",
    },
    searchPlaceholder: "Ordner suchen",
  },
  downloadInquiryButton: {
    tooltip: "Angebotsdaten herunterladen",
    excelOfferFormat: "Excel herunterladen (Angebots-Layout)",
    excelBOQFormat: "Excel herunterladen (LV-Layout)",
    quotationPdfFormat: "PDF herunterladen (Angebot)",
  },
  inquiriesTable: {
    inquiryProgressDisplay: {
      tooltip: {
        total: "{total} Positionen im LV",
        offered: "{offered} Positionen angeboten",
        completed: "{completed} Positionen abgeschlossen",
      },
    },
  },
  exportInquiryButton: "Anfrage exportieren",
  copyInquiryButton: {
    label: "Anfrage kopieren",
    copyingInquiry: "Anfrage wird kopiert ...",
  },
  exportInquiryDialog: {
    ok: "Anfrage exportieren",
    cancel: "Abbrechen",
    errors: {
      EXPORT_SFTP_FAILED_NETWORK: {
        title:
          "Netzwerkverbindung zu Server {hostname} auf Port {port} fehlgeschlagen",
        suggestion:
          "Bitte stellen Sie sicher, dass Server Hostname und Port korrekt sind, der Server vom Internet erreichbar ist und evtl. vorhandene Firewalls richtig konfiguriert sind.",
      },
      EXPORT_SFTP_UNKNOWN_HOSTNAME: {
        title:
          "Netzwerkverbindung zu Server {hostname} fehlgeschlagen (Hostname unbekannt)",
        suggestion:
          "Bitte stellen Sie sicher, dass der Server Hostname stimmt und DNS korrekt konfiguriert ist.",
      },
      EXPORT_SFTP_FAILED_AUTH: {
        title: "Authentifizierung fehlgeschlagen (Benutzername/Passwort)",
        suggestion:
          "Bitte stellen Sie sicher, dass SFTP Benutzername und Passwort korrekt sind.",
      },
      EXPORT_SFTP_FAILED_PRIVATE_KEY: {
        title: "Authentifizierung fehlgeschlagen (Privater Schlüssel)",
        suggestion:
          "Bitte stellen Sie sicher, dass der SFTP Private Schlüssel korrekt ist.",
      },
      EXPORT_SFTP_FOLDER_NOT_FOUND: {
        title: "Ordner {directory} nicht gefunden",
        suggestion:
          "Bitte stellen Sie sicher, dass der Ordner auf dem SFTP Server existiert und der SFTP-Benutzer Zugriffsrechte hat.",
      },
      EXPORT_SFTP_WRITE_FAILED: {
        title: "Order {directory} nicht schreibbar",
        suggestion:
          "Bitte stellen Sie sicher, dass der SFTP-Benutzer Schreibrechte hat.",
      },
      EXPORT_ERROR: {
        title: "Export fehlgeschlagen",
        suggestion: "Bitte kontaktieren sie den kinisto Support über {email}",
        emailAddress: "support@kinisto.com",
      },
      EXPORT_REST_FAILED_NETWORK: {
        title: "Netzwerkverbindung zu Server {url} fehlgeschlagen",
        suggestion:
          "Bitte stellen Sie sicher, dass die Server-URL korrekt ist, der Server vom Internet erreichbar ist und evtl. vorhandene Firewalls richtig konfiguriert sind.",
      },
      EXPORT_REST_FAILED_AUTH: {
        title: "Authentifizierung fehlgeschlagen",
        suggestion:
          "Bitte stellen Sie sicher, dass die HTTP Header für API-Key Authentifizierung richtig konfiguriert sind.",
      },
      EXPORT_REST_404: {
        title: "URL {url} nicht gefunden",
        suggestion:
          "Bitte stellen Sie sicher, dass die URL korrekt ist und der Server vom Internet erreichbar ist.",
      },
    },
    inquiryWasAlreadyExported: {
      title: "Anfrage bereits exportiert",
      confirmation:
        "Diese Anfrage wurde bereits exportiert. Soll sie erneut exportiert werden?",
    },
    inquiryIsNotFinalized: {
      title: "Anfrage abschließen und exportieren",
      confirmation:
        "Die Anfrage wird festgeschrieben und in das ERP System exportiert. Angebotsdaten und Positionen können anschließend in kinisto nicht mehr bearbeitet werden.",
    },
    inquiryWasFinalized: {
      title: "Anfrage exportieren",
      confirmation:
        "Die Anfrage wird exportiert in das ERP System. Angebotsdaten und Positionen können anschließend in kinisto nicht mehr bearbeitet werden.",
    },
  },
  finalizeInquiryButton: "Anfrage abschließen",
  finalizeInquiryDialog: {
    title: "Anfrage abschließen",
    confirmation:
      "Die Anfrage wird festgeschrieben. Angebotsdaten und Positionen können anschließend nicht mehr bearbeitet werden.",
    ok: "Anfrage abschließen",
    cancel: "Abbrechen",
    inquiryNotCompleteDialog: {
      title: "⚠️ Nicht alle Positionen bearbeitet!",
      message:
        "Nicht alle Positionen wurden bearbeitet. Möchten Sie die Anfrage trotzdem abschließen?",
      ok: "Anfrage trotzdem abschließen",
      cancel: "Abbrechen",
    },
    errorMessages: {
      STATUS_NOT_REVIEWING:
        "Anfrage kann nur im Status 'In Bearbeitung' abgeschlossen werden.",
      HAS_POSITION_WITH_NULL_AMOUNT:
        "Positionen mit ungültigen Mengen vorhanden. Bitte überprüfen Sie die Positionen.",
      HAS_POSITION_WITH_NULL_PRODUCT:
        "Angebotsposition(en) mit ungültigem Produkt vorhanden. Bitte überprüfen Sie die Angebotspositionen.",
      BUILDING_PROJECT_ID_REQUIRED: "Bauvorhaben ist erforderlich",
      CUSTOMER_ID_REQUIRED: "Kunde ist erforderlich",
      EXPORT_IS_NOT_CONFIGURED: "Der Export ist nicht korrekt konfiguriert.",
      EXPORT_FAILED: "Der Export ist fehlgeschlagen.",
    },
  },
  selectBOQsDialog: {
    title: "LV-Dateien auswählen",
    warningTitle: "LV-Dateien auswählen",
    warningMessage:
      "Bereits bearbeitete Positionen aus abgewählten LVs werden entfernt. Falls neue LVs ausgewählt wurden, wird die Anfrage auf Status 'Analyse' gesetzt.",
    ok: "LV-Dateien auswählen",
    cancel: "Abbrechen",
    nonPDF: "Bitte wählen Sie eine PDF-Datei aus.",
    buttonLabel: "LV-Dateien auswählen",
    disabledTooltip: "Nur für Anfragen in Bearbeitung möglich.",
  },
  statusSelectButton: {
    retryAnalysis: "Analyse erneut starten",
  },
  submissionDisplay: {
    today: "heute",
    tomorrow: "morgen",
    yesterday: "gestern",
  },
  masterDataSearchBar: {
    enterAtLeastNCharacters: "Mindestens {n} Zeichen eingeben...",
  },
  productSearchMenu: {
    noResults: "Keine passenden Produkte gefunden",
    enterAtLeastNCharacters: "Mindestens {n} Zeichen eingeben...",
  },
  productSearchBar: {
    enterAtLeastNCharacters: "Mindestens {n} Zeichen eingeben...",
  },
  customerSearchMenu: {
    noResults: "Keine passenden Kunden gefunden",
  },
  dateInput: {
    notValidDate: "Kein gültiges Datum",
  },
  numberInput: {
    valueMissing: "Wert fehlt",
  },
  inquiryPage: {
    notFound: "Diese Anfrage scheint nicht zu existieren :-(",
    positions: {
      header: "Positionen",
    },
    progress: {
      header: "Fortschritt",
      description: "relevante Positionen bearbeitet",
      finalized: "fertig",
      notFinalized: "nicht fertig",
      notOffered: "nicht angeboten",
      edit: "Positionen bearbeiten",
    },
    inquiry: {
      header: "Anfrage",
    },
    manufacturerTypes: {
      header: "Fabrikate",
    },
    files: {
      header: "Dateien",
      downloadFile: "{filename} herunterladen",
    },
    editPositionsButton: {
      label: "Positionen",
    },
    cannotEditInStatus: "Kann im Status '{status}' nicht bearbeitet werden",
    finalizedBanner: {
      title: "Anfrage abgeschlossen",
      message:
        "Diese Anfrage ist abgeschlossen und kann nicht mehr bearbeitet werden.",
    },
    analyzingBanner: {
      title: "Anfrage wird analysiert",
      message:
        "Diese Anfrage wird gerade analysiert. Einige Werte können noch nicht bearbeitet werden.",
    },
    analysisErrorBanner: {
      title: "Analyse fehlgeschlagen",
      message:
        "Die Analyse dieser Anfrage ist fehlgeschlagen. Bitte laden Sie die Anfrage erneut.",
    },
    possibleDuplicatesBanner: {
      title: "Mögliche Duplikate",
      possibleDuplicateOf: "Diese Anfrage ist möglicherweise eine Kopie von",
    },
    inquiryHistory: {
      title: "Aktivität",
      events: {
        CREATED: "{user} hat die Anfrage erstellt",
        STATUS_CHANGED:
          "{user} hat den Status der Anfrage von {oldStatus} auf {newStatus} geändert",
        EXPORTED: "{user} hat die Anfrage exportiert",
        FINALIZED: "{user} hat die Anfrage abgeschlossen",
        MOVED_TO_ANOTHER_INBOX:
          "{user} hat die Anfrage in den Posteingang {targetInboxName} verschoben",
        ASSIGNED_USER: {
          other: "{user} hat diese Anfrage {assignedUserName} zugewiesen",
          self: "{user} hat diese Anfrage sich selbst zugewiesen",
          none: "{user} hat die Zuweisung dieser Anfrage aufgehoben",
        },
        COPIED: "{user} hat die Anfrage nach {copyShortCode} kopiert",
        CREATED_AS_COPY:
          "{user} hat die Anfrage als Kopie von {originalShortCode} erstellt",
        COMMENT: "{user} hat einen Kommentar hinterlassen",
      },
      unknownUser: "Unbekannter Benutzer",
      systemUser: "kinisto",
      unknownInbox: "(gelöschter Posteingang)",
      newComment: {
        placeholder: "Kommentar hinterlassen ...",
        publish: `Kommentar abschicken (${cmdOrCtrlSymbol}+Enter)`,
      },
      editComment: "Kommentar bearbeiten",
      deleteComment: "Kommentar löschen",
      confirmDeleteComment: {
        title: "Kommentar löschen",
        message: "Soll dieser Kommentar wirklich gelöscht werden?",
        ok: "Löschen",
      },
      editedAt: "Bearbeitet {date}",
      noUserFound: "Kein Benutzer gefunden",
    },
    inquirySubscribeButton: {
      subscribe: "Benachrichtigungen aktivieren",
      unsubscribe: "Benachrichtigungen deaktivieren",
    },
  },
  inquiryPositionsPage: {
    backToInquiryButton: "Zurück zur Anfrage",
    toggleOfferedButton: {
      addToOffer: "Position anbieten",
      removeFromOffer: "Position nicht anbieten",
    },
    positionsFilter: {
      text: "Positionen suchen",
      offered: "anzubieten",
      notCompleted: "nicht fertig",
      supplierRfqs: "Lieferantenanfragen",
    },
    positionsTable: {
      editPosition: "Position bearbeiten",
      nPositions: "{positions} Positionen",
      nSelected: "{selected} / {positions} ausgewählt",
    },
    offerPositionGroup: {
      expand: "ausklappen",
      collapse: "einklappen",
      helpText:
        "Klicken zum auswählen, Eingabe drücken zum editieren, mit Pfeiltasten hoch/runter navigieren",
      missingAmount:
        "Positionen mit ungültigen Mengen vorhanden. Bitte überprüfen Sie die Positionen.",
      finalize: "fertigstellen",
      unfinalize: "als nicht fertig markieren",
      confirmDelete: {
        title: "Position löschen",
        message:
          "Sind Sie sicher, dass Sie die Position unwiderbringlich löschen möchten?",
      },
      addSupplierRfq: "Lieferanten anfragen",
      removeSupplierRfq: "Lieferantenanfrage abbrechen",
      existingSupplierRfqs: "Folgende Lieferanten wurden bereits angefragt:",
      copyProducts: "Produkte kopieren",
      pasteProducts: "Produkte einfügen",
      copyProductsConfirmation:
        "Die LV-Position enthält bereits Produkte. Möchten Sie diese überschreiben?",
      cancel: "Abbrechen",
      totalPrice: "Gesamtpreis",
      configureVariant: "Variante konfigurieren",
    },
    offerPosition: {
      addProductPlaceholder: "Produkt hinzufügen ...",
    },
    productVariantConfiguratorPopup: {
      title: "Konfiguration",
      addonUpdateError: "Fehler beim Aktualisieren der Position {addonKey}",
    },
    addOfferPositionGroup: "Position hinzufügen",
    toSupplierRequestsButton: "Lieferantenanfragen",
  },
  supplierRfqsPage: {
    title: "Lieferantenanfragen",
    backToInquiryButton: "Zurück zur Anfrage",
    editPositionsButton: "Positionen bearbeiten",
    pagination: {
      rowsPerPage: "Anfragen pro Seite",
      count: "{from}-{to} von {total}",
    },
    filterMenuButton: {
      searchBuildingProject: "Bauvorhaben suchen",
      searchShortCode: "TE-123",
    },
    attachments: {
      attachExcerpts: "LV-Auszüge anhängen",
      attachOriginalBoq: "Komplette LV(s) anhängen",
      excerpt: "Auszug",
    },
    send: "Absenden",
    markAsAnswered: "Als beantwortet markieren",
    markAsUnanswered: "Als nicht beantwortet markieren",
    deleteSupplierRfq: "Lieferantenanfrage löschen",
  },
  email: {
    subject: "Betreff",
    from: "von",
    to: "an",
    cc: "cc",
    bcc: "bcc",
    sender: "Absender",
  },
  inquiry: {
    buildingProject: "Bauvorhaben",
    createdAt: "Eingang",
    requestedSubmissionDate: "Abgabe",
    requestedCustomerSubmissionDate: "Submission",
    buildingProjectId: "Bauvorhaben-ID",
    customerId: "Kunden-ID",
    shortCode: "Anfrage-Kürzel",
  },
  inbox: {
    shortCode: "Kürzel",
    intakeAddress: "Eingangsadresse",
  },
  tag: {
    label: "Name",
    color: "Farbe",
  },
  customStatus: {
    name: "Name",
    color: "Farbe",
    originalStatus: "Originalstatus",
  },
  user: {
    email: "E-Mail",
    firstName: "Vorname",
    lastName: "Nachname",
    password: "Passwort",
  },
  product: {
    variant: "Variante",
    listPrice: "Listenpreis",
    vatTaxRate: "Steuersatz",
  },
  productUnit: {
    MINUTE: "Min.",
    HOUR: "Std.",
    DAY: "Tg.",
    WEEK: "Wo.",
    MONTH: "Mon.",
    YEAR: "Jr.",
    GRAM: "g",
    KILOGRAMM: "kg",
    TON: "t",
    MILLIMETER: "mm",
    CENTIMETER: "cm",
    CENTIMETER2: "cm²",
    METER: "m",
    METER2: "m²",
    METER3: "m³",
    KILOMETER: "km",
    LITRE: "L",
    LITER: "L",
    PIECE: "St.",
    LUMPSUM: "psch.",
    METER_WEEK: "m/Wo.",
    METER2_WEEK: "m²/Wo.",
    PIECE_WEEK: "St./Wo.",
    PALLET: "Paletten",
    SLAB: "Platten",
    PACK: "Pack",
    CARTON: "Kartons",
    ROLL: "Rollen",
    BAG: "Beutel",
    BOARD: "Tafeln",
    BUNDLE: "Bund",
    BUCKET: "Eimer",
    SACK: "Sack",
    SET: "Satz",
    PAIR: "Paar",
    SHEET: "Bahnen",
    CAN: "Dosen",
    PACKAGE: "Pakete",
    BOTTLE: "Flaschen",
    BLOCK: "Block",
    CANISTER: "Kanister",
    PINT_US: "Pint US liquid",
    ROD: "Stangen",
    BIGBAG: "BigBag",
    BOX: "Boxen",
    KILOWATT: "kW",
    KILOWATT_PEAK: "kWP",
  },
  offerPosition: {
    unitPrice: "Einheitspreis",
    totalPrice: "Gesamtpreis",
    totalPriceAlternative: "Gesamtpreis (optional)",
  },
  inboxPage: {
    notFound: "Dieser Ordner scheint nicht zu existieren :-(",
    filterMenuButton: {
      searchSender: "Absender suchen",
      searchBuildingProject: "Bauvorhaben suchen",
    },
    pagination: {
      rowsPerPage: "Anfragen pro Seite",
      count: "{from}-{to} von {total}",
    },
    sortOptions: {
      id: "ID",
      created_at: "Eingangsdatum",
      requested_submission_date: "Abgabedatum",
      requested_customer_submission_date: "Submission",
      offered_positions: "Angebotene Positionen",
      progress: "Fortschritt",
      status: "Status",
      assigned_user: "Benutzer",
    },
    ascending: "aufsteigend",
    descending: "absteigend",
    editPositions: "Positionen bearbeiten",
  },
  multiTagSelect: {
    searchTag: "Tag suchen",
  },
  contactDialog: {
    title: "Kontakt",
    text: "Für Fragen und Anregungen wenden Sie sich bitte an {email}.",
    emailAddress: "support{'@'}kinisto.com",
  },
  keyboardShortcutsDialog: {
    title: "Tastaturkürzel",
    positionsPage: "LV-Bearbeitung",
    descriptions: {
      offer: "Position anbieten / nicht anbieten",
      markFinished:
        "Position als abgeschlossen / nicht abgeschlossen markieren",
      selectPrevious: "Vorherige Position auswählen",
      selectNext: "Nächste Position auswählen",
      selectProduct: "Produkt auswählen",
      unselect: "Positionsauswahl aufheben",
      expand: "Position ausklappen",
      collapse: "Position einklappen",
      jumpToNextInput: "Zur nächsten Eingabe springen",
    },
  },
  setPasswordForm: {
    passwordStrength: {
      weak: "Zu schwaches Passwort",
      ok: "Fast gut genug ...",
      strong: "Starkes Passwort",
    },
  },
  notificationsPage: {
    label: "Mitteilungen",
    unreadNotifications: "{unreadCount} ungelesene Mitteilungen",
    markAllAsRead: "Alle als gelesen markieren",
    openInquiry: "Anfrage öffnen",
    markAsRead: "Als gelesen markieren",
    markAsUnread: "Als ungelesen markieren",
  },
  shortMonthsFrom1: {
    1: "Jan",
    2: "Feb",
    3: "Mär",
    4: "Apr",
    5: "Mai",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Okt",
    11: "Nov",
    12: "Dez",
  },
  sidebarMenu: {
    supplierRfqs: "Lieferantenanfragen",
    inquiries: "Anfragen",
    masterData: "Stammdaten",
    suppliers: "Lieferanten",
  },
  Suppliers: "Lieferanten",
  supplierSelectMenu: {
    searchPlaceholder: "Lieferanten suchen",
  },
  asyncMultiSelectMenu: {
    minSearchLength: "Bitte mindestens {minSearchLength} Zeichen eingeben",
  },
  supplierRfq: {
    sentAt: "Gesendet",
    dueDate: "Fällig",
    draft: "Entwurf",
    sent: "Gesendet",
    status: "Status",
    assignedUser: "Zugewiesener Benutzer",
    inquiry: "Anfrage",
    recipientsTo: "An",
    recipientsCc: "Cc",
    recipientsBcc: "Bcc",
    subject: "Betreff",
    body: "Nachricht",
  },
  supplierRfqStatus: {
    DRAFT: "Entwurf",
    SENT: "Gesendet",
    ANSWERED: "Beantwortet",
  },
  objects: {
    inquiry: "Anfrage",
  },
  filterChips: {
    nUsers: "{n} Benutzer",
  },
  changelog: {
    title: "Neues in kinisto",
  },
  organization: {
    details: {
      companyName: "Firmenname",
      street: "Straße",
      postCode: "Postleitzahl",
      region: "Bundesland",
      town: "Ort",
      country: "Land",
      countryCode: "Landescode",
      phoneNumber: "Telefonnummer",
      faxNumber: "Faxnummer",
      email: "E-Mail",
      website: "Website",
      vatTaxId: "Steuer-ID",
      taxId: "Steuernummer",
      registerId: "Handelsregister",
      managingDirectors: "Geschäftsführer",
      bankName: "Bankname",
      bankBic: "BIC",
      bankIban: "IBAN",
      currency: "Währung",
      logo: "Logo",
    },
  },
};
