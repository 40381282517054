export default {
  error: "Fehler",
  Dismiss: "Ausblenden",
  Confidence: "Konfidenz",
  Download: "Herunterladen",
  Export: "Exportieren",
  Delete: "Löschen",
  Cancel: "Abbrechen",
  Remove: "Entfernen",
  Update: "Aktualisieren",
  Save: "Speichern",
  Search: "Suchen",
  Upload: "Hochladen",
  Account: "Account",
  Organization: "Organisation",
  "Loading data...": "Lade Daten...",
  "Uploading files...": "Lade Dateien hoch...",
  failed: "fehlgeschlagen",
  Close: "Schließen",
  "Copy text": "Text kopieren",
  "Next document": "Nächstes Dokument",
  "Previous document": "Vorheriges Dokument",
  "Next lead": "Nächste Anfrage",
  "Previous lead": "Vorherige Anfrage",
  "Mark as reviewed": "Als geprüft markieren",
  "Mark as not reviewed": "Als ungeprüft markieren",
  of: "von",
  "Changes that you made may not be saved.":
    "Änderungen, die Sie gemacht haben, wurden möglicherweise nicht gespeichert.",
  Name: "Name",
  "Building project": "Bauvorhaben",
  "Sent at": "Gesendet",
  Submission: "Abgabe",
  Positions: "Positionen",
  Tags: "Tags",
  completed: "abgeschlossen",
  complete: "abschließen",
  uncomplete: "nicht abschließen",
  total: "insgesamt",
  offered: "angeboten",
  own: "eigen",
  neutral: "neutral",
  external: "fremd",
  "not offered": "nicht angeboten",
  "not completed": "nicht abgeschlossen",
  Manufacturer: "Hersteller",
  BOQ: "LV",
  Product: "Produkt",
  "Alternative position": "Alternativposition",
  "Product Variant": "Produktvariante",
  Customer: "Kunde",
  "Sales office": "Verkaufsbüro",
  Notes: "Anmerkungen",
  "Country code": "Ländercode",
  "VAT tax ID": "USt-IdNr.",
  Region: "Region",
  Town: "Ort",
  Street: "Straße",
  "Post code": "PLZ",
  "VAT tx ID": "USt-IdNr.",
  Amount: "Menge",
  users: "{n} Benutzer",
  tags: "1 Tag | {n} Tags",
  statuses: "1 Status | {n} Statuswerte",
  Products: "Produkte",
  Customers: "Kunden",
  "No results found": "Keine Ergebnisse gefunden",
  Unit: "Einheit",
  "Alternative Units": "Alternative Einheiten",
  "New password": "Neues Passwort",
  Hostname: "Hostname",
  "Private key": "Privater Schlüssel",
  "Directory incoming aleaud":
    "Ordner für eingehende Status IDocs (Type ALEAUD)",
  "Directory outgoing orders": "Ordner für ausgehende IDocs (Typ ORDERS)",
  "Delete aleaud files": "ALEAUD-IDocs nach erfolgreichem einlesen löschen",
  "IDOC Field SNDPRN": "IDOC Feld SNDPRN",
  "Directory outgoing aleaud":
    "Ordner für ausgehende Status IDocs (Type ALEAUD)",
  "Directory incoming products": "Ordner für eingehende IDocs (Typ MATMAS)",
  "Directory incoming customers": "Ordner für eingehende IDocs (Typ DEBMAS)",
  "Delete idocs once imported": "IDocs nach erfolgreichem Import löschen",
  Port: "Port",
  Username: "Benutzer",
  Directory: "Absoluter oder relativer Pfad zu Ordner",
  Contact: "Kontakt",
  "Keyboard shortcuts": "Tastaturkürzel",
  Inactive: "Inaktiv",
  Sort: "Sortieren",
  Filter: "Filtern",
  Active: "Aktiv",
  Yes: "Ja",
  No: "Nein",
};
