<template>
  <div>
    <product-chip
      :tabindex="0"
      v-if="!isActive && product"
      :product="product"
      :is-ai-suggestion="position?.isAiSuggestion || false"
      :confidence="position?.productConfidence"
      @click.stop.prevent="focus"
      class="cursor-pointer product-input-product-chip full-width"
      @keydown.stop.prevent.enter="focus"
    />
    <div
      v-else
      :class="{ 'product-input': true, disabled, isActive }"
      @focus="focus"
      @blur="blur"
      :tabindex="isActive ? -1 : 0"
      @click.stop.prevent
      @keydown.stop.prevent.escape="inputEl?.blur()"
    >
      <input
        v-if="isActive"
        v-model="inputText"
        :placeholder="$t('Product')"
        ref="inputEl"
        @blur="blur"
      />
      <span v-else class="text-neutral-7">
        {{ $t("inquiryPositionsPage.offerPosition.addProductPlaceholder") }}
      </span>
    </div>
    <product-search-menu
      :offer-position-group="group"
      :searchText="inputText"
      :is-active="isActive && !disabled"
      @select="chooseProduct"
    />
  </div>
</template>

<script setup lang="ts">
import ProductChip from "@/components/InquiryPositionsPage/ProductChip.vue";
import ProductSearchMenu from "@/components/InquiryPositionsPage/ProductSearchMenu.vue";
import type { OfferPosition } from "@/types/offerPosition";
import type { OfferPositionGroup } from "@/types/offerPositionGroup";
import type { Product } from "@/types/product";
import { nextTick, ref } from "vue";

const props = defineProps<{
  group: OfferPositionGroup;
  position?: OfferPosition;
  product: Product | null;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  focus: [];
  blur: [];
  "update:product": [product: Product | null];
}>();

const isActive = ref(false);
const inputText = ref("");
const inputEl = ref<HTMLInputElement | null>(null);

async function focus() {
  if (isActive.value || props.disabled) return;
  isActive.value = true;
  emit("focus");
  await nextTick();
  inputEl.value?.focus();

  // The cursor can be lost due to new pdf pages being rendered, even if the focus is still on the
  // element 🤯. Let's get it back.
  for (let i = 0; i < 3; i++) {
    await new Promise((resolve) => setTimeout(resolve, 100));
    if (document.activeElement === inputEl.value) inputEl.value?.select();
  }
}

async function blur() {
  await nextTick();
  if (!isActive.value) return;
  // don't blur if the inner input is focused
  if (inputEl.value && document.activeElement === inputEl.value) return;
  isActive.value = false;
  inputText.value = "";
  emit("blur");
}

async function chooseProduct(product: Product) {
  emit("update:product", product);
  isActive.value = false;
  inputText.value = "";
  emit("blur");
}
</script>

<style scoped lang="scss">
.product-input-product-chip {
  cursor: pointer;
  height: 24px;
  max-width: unset;
  border: 1px solid $neutral-5 !important;
  background-color: $white;
  overflow: hidden;
  outline-color: $neutral-10;

  &:hover {
    border-color: $neutral-9 !important;
  }
}

.product-input {
  cursor: text;
  background-color: white;
  border: 1px solid $neutral-5;
  height: 24px;
  padding: 1px 2px;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  > :not(:last-child) {
    margin-right: 4px;
  }

  > input {
    border: none;
    outline: none;
    background-color: transparent;
    flex: 1;
    padding: 0;
    margin: 0;
  }

  &.disabled {
    cursor: not-allowed;
    background-color: transparent;
  }

  &:hover {
    border-color: $neutral-9;
  }

  &.isActive {
    border-color: $neutral-9;
    border-width: 2px;
    outline: none;
  }
}
</style>
