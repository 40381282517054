import type { InboxRoleName, OrganizationRoleName } from "@/types/role";
import type {
  User,
  UserMeta,
  UserWithPassword,
  UserWithRole,
} from "@/types/user";
import { client, clientWithoutErrorNotifications } from "./client/client";
import type { UserSettings } from "@/types/userSettings";

export async function retrieveCurrentUserInfo() {
  const result = await client.get<{ user: User } & UserMeta>("/user/");
  return result.data;
}

export async function updateCurrentUserInfo(user: Partial<User>) {
  const result = await client.patch<User>("/user/", user);
  return result.data;
}

export async function updateCurrentUserSettings(
  settings: Partial<UserSettings>
) {
  const result = await client.patch<UserSettings>("/user/settings/", settings);
  return result.data;
}

export async function resetPasswordCurrentUser(password: string) {
  const result = await client.patch<User>("/user/", { password });
  return result.data;
}

export async function deleteCurrentUser() {
  await client.delete<User>("/user/");
}

export async function listUsers(
  organizationId: number
): Promise<UserWithRole[]> {
  const result = await client.get<UserWithRole[]>(
    `organizations/${organizationId}/users/`
  );
  return result.data;
}

export async function postUser(
  organizationId: number,
  user: Pick<
    UserWithPassword,
    "email" | "firstName" | "lastName" | "password"
  > & {
    inboxIdsToInvite: number[];
  }
) {
  const result = await client.post<User>(
    `/organizations/${organizationId}/users/`,
    user
  );
  return result.data;
}

export async function readUser(organizationId: number, userId: number) {
  const result = await client.get<User>(
    `/organizations/${organizationId}/users/${userId}/`
  );
  return result.data;
}

export async function updateUser(organizationId: number, user: Partial<User>) {
  const result = await client.patch<User>(
    `/organizations/${organizationId}/users/${user.id}/`,
    user
  );
  return result.data;
}

export async function updateUserRole(
  organizationId: number,
  user: Partial<User>,
  role: OrganizationRoleName | InboxRoleName | null,
  inboxId?: number
) {
  let url = `/organizations/${organizationId}/users/${user.id}/`;
  if (inboxId) {
    url += `?inbox=${inboxId}`;
  }
  const result = await client.patch<User>(url, {
    role,
  });
  return result.data;
}

export async function resetPasswordUser(
  organizationId: number,
  userId: number,
  password: string
) {
  const result = await client.patch(
    `/organizations/${organizationId}/users/${userId}/`,
    { password }
  );
  return result.data;
}

export async function deleteUser(organizationId: number, userId: number) {
  const result = await client.delete(
    `/organizations/${organizationId}/users/${userId}/`
  );
  return result.data;
}

export async function listInboxMembersAndNonMembers(
  organizationId: number,
  inboxId: number
): Promise<UserWithRole[]> {
  const result = await client.get(
    `organizations/${organizationId}/inboxes/${inboxId}/list_members/`
  );
  return result.data;
}

export async function listInboxMembers(
  organizationId: number,
  inboxId: number
): Promise<User[]> {
  const membersAndNonMembers = await listInboxMembersAndNonMembers(
    organizationId,
    inboxId
  );
  return membersAndNonMembers.filter((user) => user.role !== null);
}

export async function addInboxMember(
  organizationId: number,
  inboxId: number,
  userId: number
) {
  const result = await client.put(
    `organizations/${organizationId}/inboxes/${inboxId}/add_member/`,
    { user_id: userId }
  );
  return result.data;
}

export async function removeInboxMember(
  organizationId: number,
  inboxId: number,
  userId: number
) {
  const result = await client.put(
    `organizations/${organizationId}/inboxes/${inboxId}/remove_member/`,
    { user_id: userId }
  );
  return result.data;
}

export async function setInboxMembers(
  organizationId: number,
  inboxId: number,
  membersIds: number[]
) {
  const result = await client.put(
    `organizations/${organizationId}/inboxes/${inboxId}/set_members/`,
    membersIds
  );
  return result.data;
}

export async function inviteUser(
  organizationId: number,
  email: string,
  inboxIdsToInvite: number[]
) {
  const result = await client.post(
    `/organizations/${organizationId}/users/invite/`,
    {
      email,
      inboxIdsToInvite,
    }
  );
  return result.data;
}

export async function acceptInvitation(
  code: string,
  secret: string,
  firstName: string,
  lastName: string,
  password: string
) {
  const result = await clientWithoutErrorNotifications.post(`/accept_invite/`, {
    code,
    secret,
    password,
    first_name: firstName,
    last_name: lastName,
  });
  return result.data;
}

export async function listOrganizationMembers(organizationId: number) {
  const result = await client.get<User[]>(
    `/organizations/${organizationId}/users/`
  );
  return result.data;
}
