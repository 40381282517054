<template>
  <q-input
    ref="inputEl"
    :modelValue="notes"
    @update:modelValue="$emit('update:notes', $event as string | null)"
    outlined
    autogrow
    type="textarea"
    bg-color="white"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
    :disabled="disabled"
    class="notes-input"
    input-class="notes-input-input"
    :placeholder="$t('Notes')"
  />
</template>

<script setup lang="ts">
import { QInput } from "quasar";
import { onMounted, ref } from "vue";

defineProps<{
  notes: string | null;
  disabled: boolean;
}>();

defineEmits<{
  "update:notes": [notes: string | null];
  focus: [];
  blur: [];
}>();

const inputEl = ref<typeof QInput | null>(null);

onMounted(() => {
  inputEl.value?.nativeEl.setAttribute("rows", "1");
});
</script>

<style lang="scss">
.notes-input {
  font-size: inherit;
  line-height: inherit;

  .q-field__control {
    min-height: 24px !important;
    padding: 0 4px;
  }

  .q-field__control-container {
    padding: 1px 0px;
  }

  &.q-field--focused .q-field__control {
    color: $neutral-10;
  }
}
.notes-input-input {
  padding: 2px 0 !important;
  line-height: inherit;
  letter-spacing: inherit;
  min-height: 18px !important;
}
</style>
